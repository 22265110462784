let _data: any;

const Config = {
  init: (data: any) => {
    _data = data;
    console.log('Console::BuildInfo', data.version);
  },
  get: (key: string) => {
    return _data[key];
  },
  all: () => ({ ..._data })
};

export default Config;
