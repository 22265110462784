
/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';
import Config from './config';
// import 'ant-design-vue/dist/antd.less';

type LANG = 'en' | 'zh';

export const getLng = (): LANG => {
  const lng = localStorage.getItem('IDMESH_LNG');
  if (lng) return lng as LANG;
  if (
    window.navigator.language?.includes('en')
    || window.navigator.languages?.[0]?.includes('en')
  ) return 'en';
  return 'zh';
}

const boot = async () => {
  let config: any;
  const {
    VUE_APP_CONTEXT,
    VUE_APP_ENV,
    VUE_APP_VERSION,
    VUE_APP_MFE_MICROAPPS,
    // @ts-ignore
  } = process.env;
  if (VUE_APP_ENV === 'development') {
    config = {
      VUE_APP_NAME: 'idaasdev',
      VUE_APP_DOMAIN: '.idmesh.site',
      VUE_APP_HOST: 'idaasdev.idmesh.site',
      VUE_APP_LOGIN_URL: 'https://consoledev.idmesh.site',
      VUE_APP_ID: '998',
    };
  } else if (VUE_APP_ENV === 'pri-test') {
    config = {
      VUE_APP_NAME: 'idaastest',
      VUE_APP_DOMAIN: '.idmesh.site',
      VUE_APP_HOST: 'idaastest.idmesh.site',
      VUE_APP_LOGIN_URL: 'https://consoletest.idmesh.site',
      VUE_APP_ID: '1631213568475006976',
    };
  } else {
    const { data } = await axios.get('/api/v1/system/dictionaries/global', {
      params: {
        category: 'sys_config',
        key: 'client_config',
      },
    });
    const {
      consoleLoginSubDomain,
      consoleSubDomain,
      consoleId,
      mainDomain,
    } = data?.value_json ?? {};
    config = {
      VUE_APP_NAME: consoleSubDomain,
      VUE_APP_DOMAIN: `.${mainDomain}`,
      VUE_APP_HOST: `${consoleSubDomain}.${mainDomain}`,
      VUE_APP_LOGIN_URL: `https://${consoleLoginSubDomain}.${mainDomain}`,
      VUE_APP_ID: consoleId,
    };
  }
  
  Config.init({
    ...config,
    VUE_APP_CONTEXT,
    VUE_APP_ENV,
    version: VUE_APP_VERSION,
    VUE_APP_MFE_MICROAPPS,
    VUE_APP_API_GATE_PATH: '/api',
    VUE_APP_ISOLATION: 'true',
  });
  const lng = getLng();
  const [
    { default: i18nDefault },
    { default: i18nErrors },
    { default: i18nApiErrors },
  ] = await Promise.all([
    import(`@/locales/${lng}/default.json`),
    import(`@/locales/${lng}/errors.json`),
    import(`@/locales/${lng}/api-errors.json`),
  ]);
  const { boot } = await import('./boot');
  mapLang(i18nApiErrors, lng);
  boot([
    { ns: 'default', lng, resources: i18nDefault },
    { ns: 'errors', lng, resources: { ...i18nApiErrors, ...i18nErrors } },
  ]);
};

const mapLang = (obj: any, lng: 'en' | 'zh') => {
  const keys: any = {
    en: {
      Username: 'Username',
    },
    zh: {
      Username: '用户名',
    },
  }[lng];
  Object.keys(obj).forEach(key => {
    const item = obj[key];
    obj[key] = item.replace(/\$\{([a-zA-Z_]+)\}/g, (_: any, mapKey: string) => { return keys[mapKey] });
  });
}

boot();
